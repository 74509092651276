// Standard Library imports
import React, { useState } from 'react';

// External library imports
import { useLocation, useHistory } from 'react-router-dom';

// Internal module imports
import TabsComponent from '../../components/Tabs/Tabs';
import Edge from './edge';
import EdgeError from './EdgeError/EdgeError';
import EdgeStats from './EdgeStats/EdgeStats';
import AWSAccessManager from './AWSAccessManager';
import System from './system';
import Dashboard from './Dashboard';
import UploadLogFiles from './UploadedLogFiles/UploadLogFiles';
import EdgeTestLogs from './EdgeTestLogs';
import Shipments from './Shipments/Shipments';

const Resources = () => {
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);

    const [queryParamsData, setQueryParams] = useState({
        tab: queryParams.get('tab') ? Number(queryParams.get('tab')) : '',
        subTab: queryParams.get('subTab') ? Number(queryParams.get('subTab')) : '',
        customer: queryParams.get('customer') ? queryParams.get('customer') : '',
        systemCustomer: queryParams.get('systemCustomer') ? JSON.parse(queryParams.get('systemCustomer')) : '',
        awsCustomer: queryParams.get('awsCustomer') ? JSON.parse(queryParams.get('awsCustomer')) : '',
        deviceModal: queryParams.get('deviceModal') ? JSON.parse(queryParams.get('deviceModal')) : '',
        systemModal: queryParams.get('systemModal') && queryParams.get('systemModal') === 'true' ? Boolean(queryParams.get('systemModal')) : false,
        awsModal: queryParams.get('awsModal') && queryParams.get('awsModal') === 'true' ? Boolean(queryParams.get('awsModal')) : false,
        assetModal: queryParams.get('assetModal') && queryParams.get('assetModal') === 'true' ? Boolean(queryParams.get('assetModal')) : false,
        edge: queryParams.get('edge') ? JSON.parse(queryParams.get('edge')) : '',
        isDashboard: queryParams.get('isDashboard') ? queryParams.get('isDashboard') : '',
        testLogs: queryParams.get('testLogs') ? JSON.parse(queryParams.get('testLogs')) : '',
        view: queryParams.get('view') ? JSON.parse(queryParams.get('view')) : '',
        date: queryParams.get('date') ? new Date(queryParams.get('date')) : '',
        startTime: queryParams.get('startTime') ? queryParams.get('startTime') : '',
        endTime: queryParams.get('endTime') ? queryParams.get('endTime') : '',
        activationToggle: queryParams.get('activationToggle') ? queryParams.get('activationToggle') : '',
    });

    const [selectedIndex, setSelectedIndex] = useState(queryParamsData.tab ? queryParamsData.tab : 0);
    const [edgeError, setEdgeError] = useState(false);
    const [edgeStats, setEdgeStats] = useState(false);
    const [edgeDeviceId, setEdgeDeviceId] = useState();
    const [selectedEdge, setSelectedEdge] = useState('');
    const [dashboard, setDashboard] = useState({
        show: queryParamsData?.isDashboard ? queryParamsData?.isDashboard : false,
        data: {},
    });
    const [uploadLogFiles, setUploadLogFiles] = useState({
        status: false,
        data: '',
    });
    const [testLogs, setTestLogs] = useState(
        queryParamsData?.testLogs
            ? queryParamsData?.testLogs
            : {
                  status: false,
                  deviceId: '',
              }
    );
    const updateQueryParams = (type, value) => {
        let queryParamsNew = queryParams;
        if (type === 'tab') {
            queryParamsNew = new URLSearchParams();
        }
        queryParamsNew.set(type, value);
        history.push({ search: queryParamsNew.toString() });
    };

    const handleChangeTab = (i) => {
        setSelectedIndex(i);
        setEdgeError(false);
        setEdgeStats(false);
        setDashboard({ status: false, data: {} });
        setUploadLogFiles({ status: false, data: '' });
        setTestLogs({ status: false, deviceId: '' });
        updateQueryParams('tab', i);
        setQueryParams({});
    };

    let tabs = [
        {
            name: 'Edge Devices',
            component: (
                <>
                    <Edge
                        setSelectedIndex={setSelectedIndex}
                        setEdgeError={setEdgeError}
                        setEdgeDeviceId={setEdgeDeviceId}
                        setEdgeStats={setEdgeStats}
                        setDashboard={setDashboard}
                        setUploadLogFiles={setUploadLogFiles}
                        updateQueryParams={updateQueryParams}
                        queryParamsData={queryParamsData}
                        setTestLogs={setTestLogs}
                        setSelectedEdge={setSelectedEdge}
                    />
                </>
            ),
        },
        {
            name: 'Systems',
            component: (
                <>
                    <System
                        setSelectedIndex={setSelectedIndex}
                        setEdgeError={setEdgeError}
                        setEdgeDeviceId={setEdgeDeviceId}
                        setEdgeStats={setEdgeStats}
                        updateQueryParams={updateQueryParams}
                        queryParamsData={queryParamsData}
                    />
                </>
            ),
        },
        {
            name: 'AWS Credentials',
            component: (
                <>
                    <AWSAccessManager updateQueryParams={updateQueryParams} queryParamsData={queryParamsData} />
                </>
            ),
        },
        {
            name: 'Shipments',
            component: (
                <>
                    <Shipments updateQueryParams={updateQueryParams} queryParamsData={queryParamsData} />
                </>
            ),
        },

        ...(edgeError
            ? [
                  {
                      name: 'Edge Logs',
                      component: (
                          <>
                              <EdgeError edgeError={edgeError} edgeDeviceId={edgeDeviceId} selectedEdge={selectedEdge} />
                          </>
                      ),
                  },
              ]
            : []),
        ...(edgeStats
            ? [
                  {
                      name: 'Edge Stats',
                      component: (
                          <>
                              <EdgeStats edgeDeviceId={edgeDeviceId} />
                          </>
                      ),
                  },
              ]
            : []),
        ...(dashboard.show
            ? [
                  {
                      name: 'Dashboard',
                      component: (
                          <>
                              <Dashboard data={dashboard.data} updateQueryParams={updateQueryParams} queryParamsData={queryParamsData} />
                          </>
                      ),
                  },
              ]
            : []),
        ...(uploadLogFiles.status
            ? [
                  {
                      name: 'Edge Log Files',
                      component: (
                          <>
                              <UploadLogFiles deviceId={uploadLogFiles.data} setOpen={setUploadLogFiles} />
                          </>
                      ),
                  },
              ]
            : []),
        ...(testLogs.status
            ? [
                  {
                      name: 'Edge Test Logs ',
                      component: (
                          <>
                              <EdgeTestLogs deviceId={testLogs.deviceId} updateQueryParams={updateQueryParams} queryParamsData={queryParamsData} />
                          </>
                      ),
                  },
              ]
            : []),
    ];

    return (
        <div className=" ventilation">
            <TabsComponent selectedIndex={queryParamsData.tab ? queryParamsData.tab : selectedIndex} tabs={tabs} onChangeTab={handleChangeTab} />
        </div>
    );
};

export default Resources;
